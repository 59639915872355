import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, allTitle = {}, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn
          },
          {
            columns: [
              { width: '*', text: '' },
              { width: '*', text: '' },
              { width: '*', text: vm.$t('fertilizerReport.krishi_bhaban') },
              { width: '*', text: '' },
              { width: '*', text: '' }
            ],
            style: 'krishi'
          },
          { text: reportHeadData.address, style: 'header3', alignment: 'center' }
        ]

        if (reportHeadData.projectName) {
          pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        }

        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push(
          {
            columns: [
                { width: '*', text: '' },
                {
                    width: 'auto',
                    layout: 'noBorders',
                    table: {
                        style: 'tableData',
                        headerRows: 0,
                        widths: '',
                        body: [
                            [
                                { text: allTitle.lc_no, style: 'td' },
                                { text: ' : ' + vm.$n(data.lc_no), style: 'td' },
                                { text: allTitle.vesselsName, style: 'td' },
                                { text: `:  ${(i18n.locale === 'bn') ? data.ship_name_bn : data.ship_name}`, style: 'td' }
                            ],
                            [
                                { text: allTitle.sourceCountry, style: 'td' },
                                { text: `:  ${(i18n.locale === 'bn') ? data.country_name_bn : data.country_name}`, style: 'td' },
                                { text: allTitle.fertilizer_name, style: 'td' },
                                { text: `:  ${(i18n.locale === 'bn') ? data.fertilizer_name_bn : data.fertilizer_name}`, style: 'td' }
                            ],
                            [
                              { text: allTitle.memo_no, style: 'td' },
                              { text: `: ${data.memo_no}`, style: 'td' },
                              { text: allTitle.date, style: 'td' },
                              { text: `:  ${vm.$options.filters.dateFormat(data.date)}`, style: 'td' }
                            ]
                        ]
                    }
                },
                { width: '*', text: '' }
            ]
          }
        )
       data.port_allocation_ports.map(item => {
        pdfContent.push({ text: '', style: 'headerPort1', alignment: 'center' })
        const port = (i18n.locale === 'bn') ? item.port.port_name_bn : item.port.port_name
          pdfContent.push(
          {
            columns: [
                { width: '*', text: '' },
                {
                    width: 'auto',
                    layout: 'noBorders',
                    table: {
                        style: 'tableData',
                        headerRows: 0,
                        widths: '',
                        body: [
                            [
                                { text: '', style: 'td' },
                                { text: vm.$t('movement.port') + ' : ', style: 'td', alignment: 'right' },
                                { text: port, style: 'td' },
                                { text: '', style: 'td' }
                            ],
                            [
                                { text: '', style: 'td' },
                                { text: allTitle.allocated_amount + ' : ', style: 'td', alignment: 'right' },
                                { text: vm.$n(item.allocated_amount), style: 'td' },
                                { text: '', style: 'td' }
                            ]
                        ]
                    }
                },
                { width: '*', text: '' }
            ]
          }
        )
        const allRows = [
          [
            { text: allTitle.sl_no, style: 'td', alignment: 'center' },
            { text: allTitle.c_and_f_agent, style: 'td', alignment: 'center' },
            { text: allTitle.allocated_amount, style: 'td', alignment: 'right' }
          ]
        ]
        item.details.map((agent, index) => {
          allRows.push(
          [
            { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? agent.company_agent_name_bn : agent.company_agent_name, style: 'td', alignment: 'center' },
            { text: vm.$n(agent.quantity), style: 'td', alignment: 'right' }
          ]
          )
        })
        allRows.push(
          [
            { text: vm.$t('globalTrans.total'), colSpan: '2', style: 'td', alignment: 'right' },
            { },
            { text: vm.$n(item.total), style: 'td', alignment: 'right' }
          ]
        )
        allRows.push(
        [
          { text: vm.$t('movement.available'), colSpan: '2', style: 'td', alignment: 'right' },
          { },
          { text: vm.$n(item.allocated_amount - item.total), style: 'td', alignment: 'right' }
        ]
        )
        pdfContent.push(
        {
          table: {
            headerRows: 1,
            widths: ['10%', '55%', '35%'],
            body: allRows
          }
        }
        )
       })
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
            th: {
              fillColor: '#dee2e6',
              fontSize: 10,
              margin: [1, 1, 1, 1]
            },
            td: {
              fontSize: 10,
              margin: [1, 1, 1, 1]
            },
            header: {
              fontSize: 12,
              margin: [0, 0, 0, 4]
            },
            header2: {
              fontSize: 12,
              bold: true,
              margin: [0, 10, 0, 10]
            },
            headerPort1: {
              fontSize: 10,
              margin: [0, 20, 0, 0]
            },
            headerPort: {
              fontSize: 10,
              margin: [0, 4, 0, 15]
            },
            header3: {
              fontSize: 9,
              margin: [0, 0, 0, 0]
            },
            address: {
              fontSize: 9,
              margin: [0, -10, 0, 0]
            },
            tableSubHead: {
              margin: [0, 5, 0, 15]
            },
            main_head: {
              fontSize: 22
            },
            krishi: {
              margin: [0, -15, 0, 15],
              alignment: 'center'
            }
          }
        }
        pdfMake.createPdf(docDefinition, null, null, null).download('c&f-agent-appointment-management')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
