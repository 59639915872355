<template>
  <b-container>
    <b-overlay :show="loading">
      <b-row class="mb-5" >
        <b-col lg="10" md="10" sm="12" offset-lg="1" offset-md="1" style="border: 1px solid gray">
          <b-row class="p-3">
            <b-col class="text-right">
              <b-button @click="pdfExport()" class="btn btn-primary">
                <i class="ri-file-pdf-line"></i> {{  $t('globalTrans.export_pdf') }}
              </b-button>
            </b-col>
          </b-row>
          <div class="mt-5 mb-3">
            <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
              <u class="text-dark">{{ $t('movement.c_and_f_agent_appointment_management') }}</u>
            </list-report-head>
          </div>
          <div class="row">
              <div class="col-md-8 offset-md-2" style="display: flex; justify-content: center">
                <table class="text-dark" style="align-self: center;">
                    <tbody>
                    <tr>
                        <td>{{ $t('movement.lc_no') }}</td>
                        <td class="px-3"> : {{ $n(formData.lc_no, { useGrouping: false }) }}</td>
                        <td>{{ $t('movement.vesselsName') }}</td>
                        <td class="px-3"> : {{  currentLocale === 'bn' ? formData.ship_name_bn : formData.ship_name }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('movement.sourceCountry') }}</td>
                        <td class="px-3"> : {{  currentLocale === 'bn' ? formData.country_name_bn : formData.country_name }}</td>
                        <td>{{ $t('movement.fertilizer_name') }}</td>
                        <td class="px-3"> : {{  currentLocale === 'bn' ? formData.fertilizer_name_bn : formData.fertilizer_name }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('movement.memoNo') }}</td>
                      <td class="px-3"> : {{  formData.memo_no }}</td>
                      <td>{{ $t('globalTrans.date') }}</td>
                      <td class="px-3"> : {{  formData.date | dateFormat }}</td>
                    </tr>
                    </tbody>
                </table>
              </div>
          </div>
          <div v-for="(port, index1) in formData.port_allocation_ports" :key="index1" class="mt-3">
            <table class="table table-borderless table-sm table-hover mb-5 mt-3">
              <tbody>
                <tr>
                    <b-td width="25%" class="text-center">{{ }}</b-td>
                    <b-td width="25%" class="text-right">{{ $t('movement.port')}}:</b-td>
                    <b-td width="25%" >{{$i18n.locale === 'bn' ? port.port.port_name_bn : port.port.port_name}}</b-td>
                    <b-td width="25%">{{  }}</b-td>
                </tr>
                <tr>
                    <b-td width="25%" class="text-center">{{ }}</b-td>
                    <b-td width="25%" class="text-right">{{ $t('movement.allocated_amount') }}:</b-td>
                    <b-td width="25%" >{{$n(port.allocated_amount)}}</b-td>
                    <b-td width="25%">{{  }}</b-td>
                </tr>
              </tbody>
            </table>
            <!-- <div class="text-center">
              <p class="table-head">{{ $t('movement.port') }} : {{ $i18n.locale === 'bn' ? port.port.port_name_bn : port.port.port_name }}</p>
              <p class="table-head">{{ $t('movement.allocated_amount') }} : {{ $n(port.allocated_amount) }}</p>
            </div> -->
            <table class="table table-bordered table-sm table-hover mb-5 mt-3">
              <tbody>
                <tr>
                    <b-td width="10%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-td>
                    <b-td width="55%" class="text-center">{{ $t('movement.c_and_f_agent') }}</b-td>
                    <b-td width="30%" class="text-right">{{ $t('movement.allocated_amount') }}</b-td>
                </tr>
                <tr v-for="(agent, index) in port.details" :key="index">
                  <td class="text-center">{{ $n(index + 1, { useGrouping: false }) }}</td>
                  <td class="text-center">{{ currentLocale === 'bn' ? agent.company_agent_name_bn : agent.company_agent_name }}</td>
                  <td class="text-right">{{ $n(agent.quantity) }}</td>
                </tr>
                <tr>
                  <td class="text-center">{{ }}</td>
                  <td class="text-right">{{ $t('globalTrans.total') }}</td>
                  <td class="text-right">{{ $n(port.total) }}</td>
                </tr>
                <tr>
                  <td class="text-center">{{ }}</td>
                  <td class="text-right">{{ $t('movement.available') }}</td>
                  <td class="text-right">{{ $n(port.allocated_amount - port.total) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadFer.vue'
import { getCandFinfo } from '../../api/routes'
import ExportPdf from './export-pdf_details'

export default {
  props: ['lc_no'],
  components: {
    ListReportHead
  },
  async created () {
    if (this.lc_no) {
      this.getCnFData()
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      formData: {}
    }
  },
  computed: {
    currentLocale: function () {
      return this.$i18n.locale
    },
    godownInfoList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    transportAgentList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.transportAgentList.filter(item => item.status === 1)
    }
  },
  methods: {
    pdfExport () {
      const reportTitle = this.$t('movement.c_and_f_agent_appointment_management')
      const titleDatas = {
        lc_no: this.$t('movement.lc_no'),
        vesselsName: this.$t('movement.vesselsName'),
        sourceCountry: this.$t('movement.sourceCountry'),
        fertilizer_name: this.$t('movement.fertilizer_name'),
        c_and_f_agent: this.$t('movement.c_and_f_agent'),
        allocated_amount: this.$t('movement.allocated_amount'),
        port: this.$t('movement.port'),
        sl_no: this.$t('globalTrans.sl_no'),
        memo_no: this.$t('movement.memoNo'),
        date: this.$t('globalTrans.date')
      }
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.formData, titleDatas, this)
    },
    async getCnFData () {
      this.loading = true
      const result = await RestApi.getData(seedFertilizerServiceBaseUrl, getCandFinfo, { lc_no: this.lc_no })
      if (result.success) {
        this.formData = result.data
      } else {
        this.formData = {
          port_allocation_ports: []
        }
      }
      this.loading = false
    },
    getCustomDataList (data) {
      const listData = data.inter_godown_details.map(item => {
        const transportObj = this.transportAgentList.find(doc => doc.value === item.transport_agent_id)
        const transportData = {}
        if (typeof transportObj !== 'undefined') {
          transportData.transport_agent = transportObj.text_en
          transportData.transport_agent_bn = transportObj.text_bn
        } else {
          transportData.transport_agent = ''
          transportData.transport_agent_bn = ''
        }

        const toGodownObj = this.godownInfoList.find(doc => doc.value === item.to_godown_infos_id)
        const toGodownData = {}
        if (typeof toGodownObj !== 'undefined') {
          toGodownData.to_godown = toGodownObj.text_en
          toGodownData.to_godown_bn = toGodownObj.text_bn
        } else {
          toGodownData.to_godown = ''
          toGodownData.to_godown_bn = ''
        }
        return Object.assign({}, item, transportData, toGodownData)
      })
      return Object.assign({}, data, { inter_godown_details: listData })
    }
  }
}
</script>
<style scoped>
 .table-head {
    color: black;
    font-size: 14px;
    padding: 0;
    margin-bottom: 0px !important;
 }
</style>
