export const testApi = '/test'

export const lcList = '/fertilizer/procurements/fertilizer-lc-creates/list-all'
export const lcInfo = '/fertilizer/procurements/fertilizer-lc-creates/lc-info/'
export const portAllocationList = '/fertilizer/movement/port-allocations/list'
export const portAllocationStore = '/fertilizer/movement/port-allocations/store'
export const portAllocationUpdate = '/fertilizer/movement/port-allocations/update'
export const portAllocationFinalSave = '/fertilizer/movement/port-allocations/final-save'
export const portAllocationDestroy = '/fertilizer/movement/port-allocations/destroy'
export const portAllocationShow = '/fertilizer/movement/port-allocations/show/'
export const portAllocationVerifyLcNumber = '/fertilizer/movement/port-allocations/verify-lc-number'

export const reportHeadingList = '/seeds/config/report-heading/detail'
export const getCandFinfo = '/fertilizer/movement/cnf-agent-appointment/lc-wise'
export const cnfAgentAppointmentList = '/fertilizer/movement/cnf-agent-appointment/list'
export const storeCandFinfo = '/fertilizer/movement/cnf-agent-appointment/store'
export const cnfAgentAppointmentFinalSave = '/fertilizer/movement/cnf-agent-appointment/final-save'
export const cnfAgentAppointmentDestroy = '/fertilizer/movement/cnf-agent-appointment/destroy'
export const checkLcNo = '/fertilizer/movement/cnf-agent-appointment/check-lc'

// program management
export const portwiseAllocation = '/fertilizer/movement/cnf-agent-appointment/port-allocation-quantity'
export const cnfAgentCheck = '/fertilizer/movement/cnf-agent-appointment/cnf-agent-check'

// carrying-agent-wise-final-program
export const carryingAgentFinalProList = '/fertilizer/movement/carrying-agent-allocation/list'
export const carryingAgentFinalProShow = '/fertilizer/movement/carrying-agent-allocation/show'
export const carryingAgentFinalProStore = '/fertilizer/movement/carrying-agent-allocation/store'
export const carryingAgentFinalProUpdate = '/fertilizer/movement/carrying-agent-allocation/update'
export const carryingAgentFinalProFinalSave = '/fertilizer/movement/carrying-agent-allocation/final-save'
export const carryingAgentFinalProUpdateDestroy = '/fertilizer/movement/carrying-agent-allocation/destroy'
export const carryingAgentFinalProWise = '/fertilizer/movement/carrying-agent-allocation/program-wise'
export const carryingAgentFinalProTransferAgent = '/fertilizer/movement/carrying-agent-allocation/get-info-transport-agent-id'

export const programManagementList = '/fertilizer/movement/program-managements/list'
export const programManagementStore = '/fertilizer/movement/program-managements/store'
export const programManagementUpdate = '/fertilizer/movement/program-managements/update'
export const programManagementFinalSave = '/fertilizer/movement/program-managements/final-save'
export const programManagementDestroy = '/fertilizer/movement/program-managements/destroy'
export const programManagementShow = '/fertilizer/movement/program-managements/show/'
export const programManagementVerifyLcNumber = '/fertilizer/movement/program-managements/verify-lc-number'

// lori chalan recieve
export const loriChalanRecieveList = '/fertilizer/movement/receive-lori-challans/list'
export const loriChalanRecieveStore = '/fertilizer/movement/receive-lori-challans/store'
export const loriChalanRecieveUpdate = '/fertilizer/movement/receive-lori-challans/update'
export const loriChalanRecieveFinalSave = '/fertilizer/movement/receive-lori-challans/final-save'
export const loriChalanRecieveDestroy = '/fertilizer/movement/receive-lori-challans/destroy'
export const loriChalanRecieveShow = '/fertilizer/movement/receive-lori-challans/show/'
export const loriChalanRecieveVerifyLcNumber = '/fertilizer/movement/receive-lori-challans/verify-lc-number'
export const loriChalanRecieveInfo = '/fertilizer/movement/receive-lori-challans/carrying-agent-allocation/'
export const loriChalanNoList = '/fertilizer/movement/receive-lori-challans/lori-challan-no-list'

export const loriChallanNoSearch = '/fertilizer/movement/inter-godown-receive/lori-challan-no'
export const interGodownChallanReceiveList = '/fertilizer/movement/inter-godown-receive/list'
export const interGodownChallanReceiveStore = '/fertilizer/movement/inter-godown-receive/store'

// inter godown program management
export const getStockByGodown = '/fertilizer/movement/inter-godown-program-managements/current-stock-available-amount'
export const interProgramManageList = '/fertilizer/movement/inter-godown-program-managements/list'
export const interProgramManageStote = '/fertilizer/movement/inter-godown-program-managements/store'
export const interProgramManageUpdate = '/fertilizer/movement/inter-godown-program-managements/update'
export const interProgramManageFinalSave = '/fertilizer/movement/inter-godown-program-managements/final-save'
export const interProgramManageDestroy = '/fertilizer/movement/inter-godown-program-managements/destroy'
export const interProgramManageShow = '/fertilizer/movement/inter-godown-program-managements/show'

export const interGodownChallanReceiveUpdate = '/fertilizer/movement/inter-godown-receive/update'
export const interGodownChallanReceiveFinalSave = '/fertilizer/movement/inter-godown-receive/final-save'
export const interGodownChallanReceiveDestroy = '/fertilizer/movement/inter-godown-receive/destroy'
export const interGodownChallanReceiveShow = '/fertilizer/movement/inter-godown-receive/show'

// inter godown delivery
export const interGodownDeliveryList = '/fertilizer/movement/inter-godown-deliveries/list'
export const interGodownDeliveryStore = '/fertilizer/movement/inter-godown-deliveries/store'
export const interGodownDeliveryUpdate = '/fertilizer/movement/inter-godown-deliveries/update'
export const interGodownDeliveryFinalSave = '/fertilizer/movement/inter-godown-deliveries/final-save'
export const interGodownDeliveryDestroy = '/fertilizer/movement/inter-godown-deliveries/destroy'
export const interGodownDeliveryShow = '/fertilizer/movement/inter-godown-deliveries/show/'
export const interGodownDeliveryProgramInfo = '/fertilizer/movement/inter-godown-deliveries/inter-godown-info/'

export const godownInformationDetails = '/fertilizer/config/godown-information/godown-details'
