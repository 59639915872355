<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:body>
                <b-row>
                   <b-col class="col-lg-6">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('procurement.lc_no')"
                    label-for="lc_no"
                    >
                    <b-form-input
                        id="registration_no"
                        v-model="search.lc_no"
                        placeholder=""
                        ></b-form-input>
                    </b-form-group>
                   </b-col>
                  <b-col class="col-lg-6">
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('movement.vessels_name')"
                        label-for="ship_name"
                    >
                      <b-form-input
                          id="ship_name"
                          v-model="search.ship_name"
                          placeholder=""
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                    <b-col cols="12">
                        <b-button class="float-right" type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('movement.c_and_f_agent_appointment_management')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                      <router-link to="c-and-f-agent-appointment-management-form" :class="'btn btn-success btn_add_new'"><i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}</router-link>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(po_memo_no)="data">
                                          {{ $t(data.item.po_memo_no, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(lc_no)="data">
                                          {{ $n(data.item.lc_no, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(date)="data">
                                          <slot v-if="data.item.date">
                                            {{ data.item.date | dateFormat }}
                                          </slot>
                                        </template>
                                        <template v-slot:cell(total)="data">
                                          {{ $n(data.item.total, { useGrouping: false }) }}
                                        </template>
                                         <template v-slot:cell(lc_date)="data">
                                           {{ $n(parseInt(data.item.lc_date.split('-')[0])) + '-' +  $n(data.item.lc_date.split('-')[1]) + '-' + $n(data.item.lc_date.split('-')[2]) }}
                                           </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" title="View Details" v-b-modal.modal-5 class="btn_table_action table_action_view" @click="edit(data.item)"><i class="fas fa-eye"></i></a>
                                            <router-link v-if="data.item.status === 1" :to="'/seeds-fertilizer-service/fertilizer-movement/c-and-f-agent-appointment-management-form?lc_no=' + data.item.lc_no" class="btn btn-sm btn-success mr-1" size="sm"><i class="ri-ball-pen-fill m-0"></i></router-link>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" @click="remove(data.item)" v-if="data.item.status === 1"><i class="fas fa-times"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_status" @click="finalSave(data.item)" v-if="data.item.status === 1"><i class="fas fa-check"></i></a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-5" size="xl" :title="$t('movement.c_and_f_agent_appointment_management') + ' ' + $t('movement.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Details :lc_no="lc_no"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Details from './Details'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { cnfAgentAppointmentList, cnfAgentAppointmentFinalSave, lcList, cnfAgentAppointmentDestroy } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Details
    },
    data () {
        return {
            search: {
                id: 0,
                lc_no: '',
              ship_name: ''
            },
            lcList: [],
            lc_no: ''
        }
    },
    computed: {
        cnfAgentList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.cnfAgentList
        },
        fertilizerNameList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
        },
        importCountryList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.fertilizerImportCountryList.filter(item => item.status === 1)
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('movement.portAllocationEntry') : this.$t('movement.portAllocationModify')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('procurement.lc_no'), class: 'text-center' },
                { label: this.$t('movement.vesselsName'), class: 'text-center' },
                { label: this.$t('movement.fertilizerName'), class: 'text-center' },
                { label: this.$t('movement.sourceCountry'), class: 'text-center' },
                { label: this.$t('movement.memoNo'), class: 'text-center' },
                { label: this.$t('globalTrans.date'), class: 'text-center' },
                { label: this.$t('movement.totalAllocatedAmount'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'lc_no' },
                { key: 'ship_name_bn' },
                { key: 'fertilizer_name_bn' },
                { key: 'country_name_bn' },
                { key: 'memo_no' },
                { key: 'date' },
                { key: 'total' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'lc_no' },
                { key: 'ship_name' },
                { key: 'fertilizer_name' },
                { key: 'country_name' },
                { key: 'memo_no' },
                { key: 'date' },
                { key: 'total' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.getLcList()
        this.loadData()
    },
    mounted () {
      core.index()
    },
    methods: {
        changeStatusCustom (baseUrl, uri, item) {
          Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.deleteData(baseUrl, `${uri}/${item.lc_no}`).then(response => {
            if (response.success) {
              window.vm.$toast.success({
                title: 'Success',
                message: 'Data Updated Successfully',
                color: '#D6E09B'
              })
            } else {
              window.vm.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
              })
            }
            Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
          })
        },
        toggleStatusCustom (baseUrl, uri, item) {
          window.vm.$swal({
            title: window.vm.$t('globalTrans.deleteConfirmMsg'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.changeStatusCustom(baseUrl, uri, item)
            }
          })
        },
        toggleStatusCustom2 (baseUrl, uri, item) {
          window.vm.$swal({
            title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.changeStatusCustom(baseUrl, uri, item)
            }
          })
        },
        edit (item) {
          this.lc_no = item.lc_no
        },
        remove (item) {
            this.toggleStatusCustom(seedFertilizerServiceBaseUrl, cnfAgentAppointmentDestroy, item)
        },
        finalSave (item) {
          this.toggleStatusCustom2(seedFertilizerServiceBaseUrl, cnfAgentAppointmentFinalSave, item)
        },
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        loadData () {
            const params = Object.assign({}, this.search, {page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, cnfAgentAppointmentList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        async getLcList() {
          const result = await RestApi.getData(seedFertilizerServiceBaseUrl, lcList)
          if (result.success) {
            this.lcList = result.data
          } else {
            this.lcList = []
          }
        }
    }
}
</script>
<style>
  .w-15{
    width: 20%;
  }
</style>
